/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Section, Heading, Text, BlurImage } from '../../components';
import { proposal } from '../../constants';

const container = css`
  background: #ffffff;
  margin: 0;
  padding: 1rem;
  padding-bottom: 0;
`;
const imgContainer = css`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  & div {
    height: 125px;
    width: calc(100% + 32px);
  }
  & div:first-of-type {
    background: #fff;
  }
  & div:last-of-type {
    background: #105e26;
  }
  @media (min-width: 481px) {
    & div {
      height: 225px;
    }
  }
  @media (min-width: 769px) {
    & div {
      height: 250px;
    }
  }
`;
const proposalImg = css`
  width: 100%;
  height: auto;
  border: 4px solid #fff;
  border-radius: 4px;
  position: absolute;

  @media (min-width: 769px) {
    width: 750px;
  }
`;

export const OurStory: React.FunctionComponent = () => {
  return (
    <Section cssStyle={container}>
      <Heading level={2} variant="dark">
        Our Story
      </Heading>
      <Text
        level={3}
        variant="dark"
        cssStyle={css`
          margin-top: 1rem;
        `}
      >
        We grew up ten minutes from each other in Beaver, PA. We attended the
        same school for seven years and both graduated from Beaver Area High
        School in 2014, but never really talked too much during that time. There
        may have been a few texts shared the summer before our senior year of
        high school, and even a couple tweets about our shared love of the tv
        show “Dexter,” but nothing too romantic. We are not sure if it was the
        “made up” (according to Evan) Pitt vs Penn State rivalry or the love for
        movies that brought us together, but in the summer leading up to our
        senior year of college, we finally started to get to know one another.
        Evan started attending more group friend events, such as bowling, in
        hopes that he could get in a few words with Alexa.
      </Text>
      <Text
        level={3}
        variant="dark"
        cssStyle={css`
          margin: 1rem 0;
        `}
      >
        From that point on we would spend every weekend together. We would spend
        all week counting down the days until Friday evening when Evan would
        take the trip to Oakland to visit Alexa at Pitt for the weekend. We
        spent the weekends exploring new areas, places to eat, and watching
        countless movies. We graduated the following spring, and then moved in
        together in downtown Pittsburgh. We still live in the same apartment,
        with our adopted kitty daughter Kida, and continue to explore not just
        the Pittsburgh area, but across the globe (Alexa even got Evan to use
        his passport for the first time for a trip to Jamaica). No matter where
        we go next, this place will always have a special place in both of our
        hearts.
      </Text>
      <Text level={3} variant="dark">
        On Saturday, January 16, 2021, Evan proposed under the archway of our
        apartment building. Alexa knew a proposal was in the near future (due to
        her inability to not be a control freak), but was still caught off guard
        the night that it happened. Alexa even made Evan get back down on one
        knee because she couldn&apos;t remember if he said the exact words “will
        you marry me” at the end of his beautifully crafted speech (he did, she
        just blacked out for a moment). Although that was one of our happiest
        days yet, we know that there will be many others in our future together
        - beginning with October 1, 2022.
      </Text>
      <div css={imgContainer}>
        <div />
        <BlurImage
          alt="Evan proposing to Alexa under Pennsylvanian archway"
          base64={proposal}
          src="/assets/proposal.jpg"
          cssStyle={proposalImg}
        />
        <div />
      </div>
    </Section>
  );
};
