/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Section, Heading, Text, Link, BlurImage } from '../../components';
import { holding } from '../../constants';

const container = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 769px) {
    flex-direction: row;
    margin: 4rem 1rem;
  }
`;
const textContainer = css`
  margin-top: 2rem;
  & > h2,
  & p:last-of-type {
    text-align: center;
  }

  @media (min-width: 769px) {
    max-width: 600px;
    margin-left: 2rem;
    margin-top: 0;
    & > h2 {
      text-align: start;
    }
  }

  @media (min-width: 993px) {
    margin-left: 4rem;
  }
`;
const holdingImg = css`
  height: 500px;
  width: auto;
  border: 4px solid #fff;
  border-radius: 4px;
  margin-top: 1rem;

  @media (min-width: 993px) {
    margin-top: 0;
  }
`;
const text = css`
  margin: 1rem 0;
`;

export const Introduction: React.FunctionComponent = () => {
  return (
    <Section cssStyle={container}>
      <BlurImage
        alt="Evan holding Alexa up under lights"
        base64={holding}
        src="/assets/holding.jpg"
        cssStyle={holdingImg}
      />
      <div css={textContainer}>
        <Heading level={2}>Welcome to our wedding website!</Heading>
        <Text level={3} cssStyle={text}>
          We appreciate you taking the time to check out the details for our big
          day. Here is a quick overview:
        </Text>
        <Text level={3} cssStyle={text}>
          Our wedding is set for Saturday, October 01, 2022. This is a black tie
          event. The ceremony and reception will both be held at the{' '}
          <Link href="https://goo.gl/maps/iPyiwK2HMVBZbZvp8">
            Carnegie Museum of Art and Natural History
          </Link>
          . The ceremony will begin at 6:00 PM in the Hall of Architecture. A
          cocktail hour will immediately follow on the Music Hall Foyer balcony.
          The night will continue in the Music Hall Foyer with plenty of food,
          dancing, and cookies.
        </Text>
        <Text level={3} cssStyle={text}>
          We cannot wait for you to celebrate this huge milestone in our lives.
          Until then, feel free to check out the rest of the details on the site
          and reach out to either of us with any questions.
        </Text>
        <Text level={3}>Love, Alexa &amp; Evan</Text>
      </div>
    </Section>
  );
};
