/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { BlurImage, Section } from '../../components';
import { handHolding, laughing, spinning } from '../../constants';

const section = css`
  background: #ffffff;
  margin: 0;
  overflow-x: scroll;
  padding: 1rem 0;

  @media (min-width: 993px) {
    overflow-x: hidden;
  }
`;
const imageContainer = css`
  align-items: center;
  display: flex;
  justify-content: center;
  @media (min-width: 993px) {
    justify-content: center;
  }
`;
const img = css`
  height: auto;
  width: 100vw;

  @media (min-width: 993px) {
    height: 525px;
    width: auto;
  }
  @media (min-width: 1281px) {
    height: 600px;
  }
`;
const endImgs = css`
  ${img};
  display: none;

  @media (min-width: 993px) {
    display: block;
  }
`;
const middleImg = css`
  ${imageContainer};
  flex-direction: column;

  @media (min-width: 993px) {
    margin: 0 1rem;
  }
`;

export const Hero: React.FunctionComponent = () => {
  return (
    <Section cssStyle={section}>
      <div css={imageContainer}>
        <BlurImage
          alt="Holding hands with ring in focus"
          base64={handHolding}
          src="/assets/hand-holding.jpg"
          cssStyle={endImgs}
        />

        <div css={middleImg}>
          <BlurImage
            alt="Smiling and laughing into hands"
            base64={laughing}
            src="/assets/laughing.jpg"
            cssStyle={img}
          />
        </div>
        <BlurImage
          alt="Holding hands with ring in focus"
          base64={spinning}
          src="/assets/spinning.jpg"
          cssStyle={endImgs}
        />
      </div>
    </Section>
  );
};
