/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Section, Heading, Text, Link } from '../../components';
import crateAndBarrel from '../../assets/crate-and-barrel.svg';
import potteryBarn from '../../assets/pottery-barn.svg';
import westElm from '../../assets/west-elm.svg';
import williamsSonoma from '../../assets/williams-sonoma.svg';

const section = css`
  background: #ffffff;
  margin: 0;
  padding: 1rem;
  margin-top: -1px;
`;
const imageList = css`
  align-items: center;
  display: flex;
  justify-content: space-around;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const image = css`
  margin: 2rem 0;
  @media (max-width: 767px) {
    &:first-of-type,
    &:last-of-type {
      margin: 0;
    }
  }
  @media (min-width: 768px) {
    & img {
      width: 125px;
    }
  }
  @media (min-width: 992px) {
    & img {
      width: 175px;
    }
  }
`;

export const Registry: React.FunctionComponent = () => {
  const registryItems = [
    {
      link: 'https://www.crateandbarrel.com/gift-registry/alexa-spokane-and-evan-mcstay/r6418353',
      altText: 'Crate and Barrel logo',
      img: crateAndBarrel,
    },
    {
      link: 'https://www.williams-sonoma.com/registry/fk59d5hvdm/registry-list.html',
      altText: 'Williams Sonoma logo',
      img: williamsSonoma,
    },
    {
      link: 'https://www.potterybarn.com/m/registry/fk59d5hvdm/registry-list.html',
      altText: 'Pottery Barn logo',
      img: potteryBarn,
    },
    {
      link: 'https://www.westelm.com/registry/fk59d5hvdm/registry-list.html',
      altText: 'West Elm logo',
      img: westElm,
    },
  ];

  return (
    <Section cssStyle={section}>
      <Heading level={2} variant="dark">
        Registry
      </Heading>
      <Text
        level={3}
        variant="dark"
        cssStyle={css`
          margin: 1rem 0;
        `}
      >
        Please click on each logo to see what we have registered at the
        following stores:
      </Text>
      <div css={imageList}>
        {registryItems.map((item) => (
          <Link href={item.link} key={item.altText} cssStyle={image}>
            <img alt={item.altText} src={item.img} />
          </Link>
        ))}
      </div>
    </Section>
  );
};
