/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Section, Heading, Text, Link } from '../../components';

const question = css`
  color: #dfc16d;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

export const FAQ: React.FunctionComponent = () => {
  const questionAnswer = [
    {
      question: `When should I have my RSVP in by?`,
      answer: `RVSPs should be mailed back to us by the 1st of September.`,
    },
    {
      question: `What should I do if I can't make it?`,
      answer: `You will be missed! Please let us know as soon as possible 
        and RSVP "no" so that we can plan accordingly.`,
    },
    {
      question: `Do I get a plus one?`,
      answer: `We are limited in the total number of guests 
        that we are able to invite to the event. Because of 
        this, not everyone will be extended a plus one. You can 
        see on your invitation envelope if you have a guest invited.`,
    },
    {
      question: `Are children welcome?`,
      answer: `While we love your little ones, our wedding is 
        going to be an adults-only event so that everyone can relax 
        and enjoy the evening. We appreciate you making arrangements 
        ahead of time and leaving the kids at home you can celebrate 
        with us.`,
    },
    {
      question: `What should I wear?`,
      answer: `This will be a black tie event. We wish for ladies to 
        wear a gown or floor-length dress and gentlemen to wear a tuxedo 
        or dark suit and tie/bowtie (the tie/bowtie does not have to be black in color).`,
    },
    {
      question: `What time should I arrive?`,
      answer: `The ceremony will start promptly at 6:00 PM. Please arrive 
        by 5:45 PM to make sure everyone is on time and we can get the 
        party started as scheduled.`,
    },
    {
      question: `Where can I park?`,
      answer: `You can park in the museum's parking lot that is around 
        the corner from the building. Parking in this garage will be free for
        wedding guests. Please tell the parking booth that you are with the 
        Spokane-McStay wedding. You can review the museum parking details with this`,
      link: <Link href="https://cmoa.org/visit/directions-parking/">link</Link>,
    },
    {
      question: `Will there be transportation?`,
      answer: `Yes, a shuttle will be provided for guest transportation to the 
        museum from both hotels between 5:00 and 5:45 PM. At the end of the 
        evening, the shuttle will return guests to both hotels from 10:45 to 11:45 PM.`,
    },
    {
      question: `Who took these beautiful photos?`,
      answer: `Kayla Lynn Photos...who will also be capturing memories on our wedding day!`,
    },
    {
      question: `Who made this awesome website?`,
      answer: `Evan designed and developed the website (with Alexa's help). Feel free to reach 
        out to hello@emcdesigns.co with any of your website needs.`,
    },
  ];

  return (
    <Section>
      <Heading level={2}>FAQ</Heading>
      {questionAnswer.map((item) => (
        <React.Fragment key={item.question}>
          <Text level={3} cssStyle={question}>
            {item.question}
          </Text>
          <Text
            level={3}
            cssStyle={css`
              margin-bottom: 2rem;
              &:last-of-type {
                margin-bottom: 0;
              }
            `}
          >
            {item.answer} {item.link}
            {item.link && '.'}
          </Text>
        </React.Fragment>
      ))}
    </Section>
  );
};
