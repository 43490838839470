import React from 'react';
import { Navbar } from '../../components/index';
import { Contact } from './Contact';
import { FAQ } from './FAQ';
import { Hero } from './Hero';
import { Introduction } from './Introduction';
import { OurStory } from './OurStory';
import { Registry } from './Registry';
import { SmilingCrossCut } from './SmilingCrossCut';
import { TravelAndStay } from './TravelAndStay';

export const Home: React.FunctionComponent = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Introduction />
      <OurStory />
      <TravelAndStay />
      <Registry />
      <SmilingCrossCut />
      <FAQ />
      <Contact />
    </>
  );
};
