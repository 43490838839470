/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';

interface Props {
  children: React.ReactNode;
  cssStyle?: SerializedStyles;
}

const section = css`
  margin: 1rem;
`;

export const Section: React.FunctionComponent<Props> = ({
  children,
  cssStyle,
}: Props) => {
  return <section css={[section, cssStyle]}>{children}</section>;
};
