/* eslint-disable max-len */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Section, Heading, Text, Link, BlurImage } from '../../components';
import hotelImg from '../../assets/hotel.svg';
import { champagne, kissing, laughingTears } from '../../constants';

const heading = css`
  margin: 1rem;
`;
const hotelList = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 767px) {
    flex-direction: column;
    margin: 2rem;
  }
`;
const hotelStyle = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & > a {
    height: 120px;
    margin-bottom: 1rem;
  }
  @media (max-width: 767px) {
    &:first-of-type {
      margin-bottom: 2rem;
    }
  }
`;
const highlight = css`
  color: #dfc16d;
`;
const imgSection = css`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 180%;
  overflow-x: auto;
  white-space: nowrap;

  & div {
    height: 125px;
    width: 200%;
    max-width: 1000px;
  }
  & div:first-of-type {
    background: #105e26;
  }
  & div:last-of-type {
    background: #fff;
  }
  @media (min-width: 481px) {
    align-items: center;
    width: 100%;

    & div {
      height: 150px;
      width: 100%;
      max-width: unset;
    }
  }
`;
const internalImgContainer = css`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  padding-left: 1rem;
  & > img:nth-of-type(2) {
    margin: 0 1rem;
  }

  @media (min-width: 481px) {
    width: 100%;
    justify-content: center;
    padding-left: 0;
    & div {
      height: 125px;
    }
  }
  @media (min-width: 993px) {
    & div {
      height: 175px;
    }
  }
`;
const img = css`
  border: 4px solid #fff;
  border-radius: 4px;
  height: 200px;
  width: auto;

  @media (min-width: 769px) {
    height: 250px;
  }
`;

export const TravelAndStay: React.FunctionComponent = () => {
  const hotels = [
    {
      name: 'The Oaklander Hotel',
      address: '5130 Bigelow Boulevard, Pittsburgh, Pennsylvania 15213',
      link: 'https://www.marriott.com/event-reservations/reservation-link.mi?id=1614782120865&key=GRP&app=resvlink',
      phoneNumber: '412-578-8500',
    },
    {
      name: 'Wyndham Pittsburgh University Center',
      address: '100 Lytton Avenue, Pittsburgh, Pennsylvania 15213',
      link: 'https://www.wyndhamhotels.com/wyndham/pittsburgh-pennsylvania/wyndham-pittsburgh-university-center/rooms-rates?&checkInDate=10/01/2022&checkOutDate=10/2/2022&groupCode=10016841SP',
      phoneNumber: '877-999-3223',
    },
  ];

  return (
    <Section
      cssStyle={css`
        margin: 0;
        overflow-x: hidden;
      `}
    >
      <Heading level={2} cssStyle={heading}>
        Travel &amp; Stay
      </Heading>
      <Text
        level={3}
        cssStyle={css`
          margin: 1rem;
        `}
      >
        For your convenience, a block of rooms has been reserved at both the
        Oaklander and the Wyndham hotels in Oakland. If calling to make your
        reservation, please mention the Spokane - McStay Wedding in order to
        receive a discounted rate. The last day to reserve rooms for the group
        rates is <span css={highlight}>Friday, September 9, 2022</span>. Please
        click on each hotel icon for a direct link to the hotel block.
      </Text>

      <div css={hotelList}>
        {hotels.map((hotel) => (
          <div css={hotelStyle} key={hotel.name}>
            <Link href={hotel.link}>
              <img
                alt="hotel icon"
                src={hotelImg}
                style={{ height: '120px' }}
              />
            </Link>
            <Heading level={3}>{hotel.name}</Heading>
            <Text
              level={3}
              cssStyle={css`
                margin: 0.5rem 0;
              `}
            >
              {hotel.address}
            </Text>
            <Text level={3}>
              <Link href={`tel:${hotel.phoneNumber}`} newTab={false}>
                {hotel.phoneNumber}
              </Link>
            </Text>
          </div>
        ))}
      </div>

      <Text
        level={3}
        cssStyle={css`
          margin: 2rem 1rem 1rem;
        `}
      >
        Each hotel provides guest parking for an additional fee. There will be
        complementary parking at the Carnegie Museum of Art parking garage. A
        shuttle will be provided for guest transportation from the hotels to the
        museum for the ceremony and reception, and back to the hotels following
        the celebration.
      </Text>
      <Text
        level={3}
        cssStyle={css`
          margin: 1rem;
        `}
      >
        All wedding guests will be given complementary admission to the museum
        during regular business hours on the day of the wedding. Upon arrival,
        visit any of the admissions desks and let them know you are a guest of
        the Spokane-McStay wedding.
      </Text>

      <div css={imgSection}>
        <div />
        <div css={internalImgContainer}>
          <BlurImage
            alt="Evan and Alexa celebrating by drinking champagne"
            base64={champagne}
            src="/assets/champagne.jpg"
            cssStyle={img}
          />
          <BlurImage
            alt="Evan and Alexa kissing"
            base64={kissing}
            src="/assets/kissing.jpg"
            cssStyle={img}
          />
          <BlurImage
            alt="Evan and Alexa laughing while Alexa wipes tears"
            base64={laughingTears}
            src="/assets/laughing-tears.jpg"
            cssStyle={img}
          />
        </div>
        <div />
      </div>
    </Section>
  );
};
