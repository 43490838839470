/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { BlurImage } from '../../components';
import { smiling } from '../../constants';

const smilingImg = css`
  height: auto;
  margin: 0;
  width: 100%;
`;

export const SmilingCrossCut: React.FunctionComponent = () => {
  return (
    <BlurImage
      alt="Evan and Alexa smiling"
      base64={smiling}
      src="/assets/smiling.jpg"
      cssStyle={smilingImg}
    />
  );
};
