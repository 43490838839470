/* eslint-disable react/jsx-props-no-spreading */
/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';

interface Props {
  children: ReactNode;
  level: 1 | 2 | 3 | 4;
  cssStyle?: SerializedStyles;
  variant?: 'light' | 'dark';
}

const baseStyles = css`
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.4;
`;
const xs = css`
  ${baseStyles};
  font-size: 0.75rem;
`;
const sm = css`
  ${baseStyles};
  font-size: 0.875rem;
`;
const md = css`
  ${baseStyles};
  font-size: 1rem;
`;
const lg = css`
  ${baseStyles};
  font-size: 1.125rem;
`;
const lightTheme = css`
  color: #ffffff;
`;
const darkTheme = css`
  color: #333333;
`;

export const Text: React.FunctionComponent<Props> = ({
  children,
  cssStyle,
  level,
  variant = 'light',
}: Props) => {
  const colorTheme = variant === 'light' ? lightTheme : darkTheme;

  const getSize = (size: number): SerializedStyles | undefined => {
    return {
      1: xs,
      2: sm,
      3: md,
      4: lg,
    }[size];
  };

  return <p css={[getSize(level), colorTheme, cssStyle]}>{children}</p>;
};
