/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { css, SerializedStyles } from '@emotion/react';

interface Props {
  alt: string;
  base64: string;
  src: string;
  cssStyle?: SerializedStyles;
}

const img = css`
  @media (max-width: 1280px) {
    object-fit: cover;
  }
`;

const preloadImage = (src: string): Promise<void> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve();
    image.src = src;
  });
};

export const BlurImage: React.FunctionComponent<Props> = ({
  alt,
  base64,
  src,
  cssStyle,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const currentSrc = isLoaded ? src : base64;

  const loadImage = useCallback(async (): Promise<void> => {
    await preloadImage(src);
    setIsLoaded(true);
  }, [src]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  return <img alt={alt} css={[img, cssStyle]} src={currentSrc} />;
};
