/** @jsxImportSource @emotion/react */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { css, Global } from '@emotion/react';
import { Home, Landing } from './pages';

const globalStyles = css`
  * {
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-focus-ring-color: transparent;
  }
  body {
    background-color: #105e26;
    font-size: 16px;
    margin: 0;
  }
`;
const main = css`
  height: 100%;
`;

export const App: React.FunctionComponent = () => {
  return (
    <>
      <Global styles={globalStyles} />
      <main css={main}>
        <Switch>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </main>
    </>
  );
};
