/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';

interface Props {
  children: ReactNode;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  cssStyle?: SerializedStyles;
  variant?: 'light' | 'dark';
}

const baseStyles = css`
  font-family: 'Arapey', serif !important;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
`;
const h1 = css`
  ${baseStyles};
  font-size: 3rem;
`;
const h2 = css`
  ${baseStyles};
  font-size: 2rem;
`;
const h3 = css`
  ${baseStyles};
  font-size: 1.5rem;
`;
const h4 = css`
  ${baseStyles};
  font-size: 1.25rem;
`;
const h5 = css`
  ${baseStyles};
  font-size: 1rem;
`;
const h6 = css`
  ${baseStyles};
  font-size: 0.875rem;
`;
const lightTheme = css`
  color: #ffffff;
`;
const darkTheme = css`
  color: #333333;
`;

export const Heading: React.FunctionComponent<Props> = ({
  children,
  cssStyle,
  level,
  variant = 'light',
}: Props) => {
  const colorTheme = variant === 'light' ? lightTheme : darkTheme;

  const getSize = (size: number): JSX.Element | undefined => {
    return {
      1: <h1 css={[h1, colorTheme, cssStyle]}>{children}</h1>,
      2: <h2 css={[h2, colorTheme, cssStyle]}>{children}</h2>,
      3: <h3 css={[h3, colorTheme, cssStyle]}>{children}</h3>,
      4: <h4 css={[h4, colorTheme, cssStyle]}>{children}</h4>,
      5: <h5 css={[h5, colorTheme, cssStyle]}>{children}</h5>,
      6: <h6 css={[h6, colorTheme, cssStyle]}>{children}</h6>,
    }[size];
  };

  return <>{getSize(level)}</>;
};
