/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import monogram from '../../assets/monogram.jpg';

const navbar = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #105e26;
`;
const img = css`
  height: 80px;
`;

export const Navbar: React.FunctionComponent = () => {
  return (
    <header>
      <nav css={navbar}>
        <img src={monogram} alt="McStay Wedding Monogram" css={img} />
      </nav>
    </header>
  );
};
