/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';

interface Props {
  children: ReactNode;
  href: string;
  newTab?: boolean;
  cssStyle?: SerializedStyles;
}

const link = css`
  color: #dfc16d;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.4;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Link: React.FunctionComponent<Props> = ({
  children,
  href,
  newTab = true,
  cssStyle,
}: Props) => {
  return (
    <a
      css={[link, cssStyle]}
      href={href}
      rel="noopener noreferrer"
      target={newTab ? '_blank' : '_self'}
    >
      {children}
    </a>
  );
};
