/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { BlurImage, Heading, Section } from '../../components';
import { landing } from '../../constants';

const section = css`
  margin: 0;
  overflow: hidden;
`;
const landingImg = css`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;
`;
const headings = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  & > h1:first-of-type {
    margin-top: 5rem;
  }
`;
const separation = css`
  display: flex;
  align-items: center;
  justify-content: center;

  & > h4 {
    line-height: 1rem;
  }
`;
const hr = css`
  margin: 0 0.5rem;
  width: 100px;
`;
const button = css`
  border: 1px solid #ffffff;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.125rem;
  margin-top: 1rem;
  padding: 1rem 5rem;
  text-transform: uppercase;
`;

export const Landing: React.FunctionComponent = () => {
  const history = useHistory();
  return (
    <Section cssStyle={section}>
      <BlurImage
        alt="Evan and Alexa under the Pennsylvanian Archway"
        base64={landing}
        src="/assets/landing.jpg"
        cssStyle={landingImg}
      />
      <div css={headings}>
        <Heading level={1} variant="light">
          Alexa
        </Heading>
        <div css={separation}>
          <hr css={hr} />
          <Heading level={4} variant="light">
            &amp;
          </Heading>
          <hr css={hr} />
        </div>
        <Heading level={1} variant="light">
          Evan
        </Heading>
        <button
          css={button}
          type="button"
          onClick={() => history.push('/home')}
        >
          Enter
        </button>
      </div>
    </Section>
  );
};
