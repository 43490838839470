/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Section, Heading, Text } from '../../components';

const section = css`
  background: #ffffff;
  margin: 0;
  padding: 1rem;
`;
const contactList = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const contactStyle = css`
  text-align: center;
  @media (max-width: 767px) {
    &:first-of-type {
      margin-bottom: 2rem;
    }
  }
`;

export const Contact: React.FunctionComponent = () => {
  const contacts = [
    {
      name: 'Alexa',
      email: 'alexaspokane@yahoo.com',
      phone: '724.630.5625',
    },
    {
      name: 'Evan',
      email: 'mcstaye@gmail.com',
      phone: '724.462.3219',
    },
  ];

  return (
    <Section cssStyle={section}>
      <Heading level={2} variant="dark">
        Contact
      </Heading>
      <Text
        level={3}
        variant="dark"
        cssStyle={css`
          margin: 1rem 0;
        `}
      >
        If you have any questions, please do not hesitate to reach out to either
        of us.
      </Text>
      <div css={contactList}>
        {contacts.map((contact) => (
          <div css={contactStyle} key={contact.name}>
            <Heading level={3} variant="dark">
              {contact.name}
            </Heading>
            <Text
              level={3}
              variant="dark"
              cssStyle={css`
                margin: 0.5rem 0;
              `}
            >
              {contact.email}
            </Text>
            <Text level={3} variant="dark">
              {contact.phone}
            </Text>
          </div>
        ))}
      </div>
    </Section>
  );
};
